import { createTheme } from "@mui/material";

export const themeMUI = createTheme({
    palette: {
        primary: {
            main: "#0098FF"
        }
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h2',
                    h2: 'h2',
                    h3: 'h2',
                    h4: 'h2',
                    h5: 'h2',
                    h6: 'h2',
                    subtitle1: 'h2',
                    subtitle2: 'h2',
                    body1: 'span',
                    body2: 'span',
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: {
                        variant: 'contained',
                        color: 'primary',
                    },
                    style: {
                        borderRadius: '16px',
                    }
                },
                {
                    props: {
                        variant: 'text',
                    },
                    style: {
                        borderRadius: '16px',
                    }
                }
            ]
        }
    },
});