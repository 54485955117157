import React from 'react'
import { Box, Grid, Stack } from "@mui/material"

import { Button } from "components/Button/Button"
import { Typography } from "components/Typography/Typography"
import { IStepsProps } from "../Invite"

import WelcomeImage from '../../../assets/welcome-invite.svg'

export const StepWelcome = ({ handleNextStep }: IStepsProps) => {
    const onOpenRegulamento = () => {
        const pdfUrl = 'https://arquivos.nhgtech.com.br/files/Regulamento_Convidar_Amigos.pdf';
        window.open(pdfUrl, '_blank');
    }

    return (
        <Grid>
            <Stack>
                <Typography variant="h4">Convide Totvers para o Cuid@r e concorra a prêmios!</Typography>
                <Typography variant="body1" mt={2}>Promova saúde e bem-estar! Envie convites, ilimitadamente, para colegas e dependentes aproveitarem as funcionalidades do aplicativo.</Typography>

                <Box marginY={5} display={'flex'} justifyContent={'center'}>
                    <img src={WelcomeImage} alt="Welcome" style={{ maxWidth: '350px', width: '100%', height: 'auto' }} />
                </Box>

                <Typography>Quanto mais convites aceitos, mais chances você tem de conquistar nossos prêmios.</Typography>
            </Stack>

            <Stack mt={6} spacing={2} display="flex" alignItems='center' flex={1}>
                <Button variant="contained" fullWidth onClick={handleNextStep}>Convidar</Button>
                <Button fullWidth onClick={onOpenRegulamento}>Regulamento da campanha</Button>
            </Stack>
        </Grid>
    )
}