import { useCallback, useState } from "react"

import { Box, IconButton } from "@mui/material"
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import {
    StepSendInvite,
    StepWelcome,
    StepFeedback
} from "./containers"

export interface IStepsProps {
    handleNextStep: () => void
}

export const InviteScreen = () => {
    const [step, setStep] = useState(0)

    const handleNextStep = useCallback(() => {
        if (step === 2) {
            return setStep(1)
        }

        setStep((old) => old + 1)
    }, [step])

    const handleBackStep = useCallback(() => {
        setStep((old) => old - 1)
    }, [])

    const steps = (props: IStepsProps): { [name: number]: JSX.Element } => ({
        0: <StepWelcome {...props} />,
        1: <StepSendInvite {...props} />,
        2: <StepFeedback {...props} />,
    })

    return (
        <Box>
            {step > 0 && (
                <IconButton onClick={handleBackStep}>
                    <ChevronLeft />
                </IconButton>
            )}

            <Box padding={2}>
                {steps({ handleNextStep })[step]}
            </Box>
        </Box>
    )
}