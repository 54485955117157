import { useLocation } from 'react-router-dom'

import { Box, Grid, Stack } from '@mui/material'

import { Typography } from 'components/Typography/Typography'
import { IStepsProps } from '../Invite'
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share'
import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'

export const StepSendInvite = ({ handleNextStep }: IStepsProps) => {
  const [sharedUrl, setSharedUrl] = useState('')

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const indicatorId = queryParams.get('indicador') || ''

  const buildLink = async (codigoIndicador: string | number) => {
    try {
      const link = `https://cuidarintegra.page.link/TG78?indicador=${codigoIndicador}`

      const response = await axios.post(
        'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBvtqDIhKUUjuwKT3_Pvj9Ma8qHdwdmlOc',
        {
          dynamicLinkInfo: {
            domainUriPrefix: 'https://cuidarintegra.page.link',
            link: link,
            androidInfo: {
              androidPackageName: 'br.com.healthyou.cuidarintegra'
            }
          }
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      console.log(response.data)
      if (response.status === 200) {
        setSharedUrl(response.data.shortLink)
      } else {
        throw new Error('Erro ao encurtar o link')
      }
    } catch (error) {
      console.error('Erro:', error)
      throw error
    }
  }

  const titleMemo = useMemo(() => {
    const title =
      'Venha aproveitar comigo todos os recursos do Cuid@r para obter mais saúde e bem-estar! Anexe exames e receitas, informe alergias, registre e acompanhe indicadores de saúde (peso, glicemia e outros), e muito mais! Faça seu primeiro acesso no aplicativo clicando no link: '

    return title.replace(':indicador', indicatorId)
  }, [indicatorId])

  const handleSendInvite = () => {
    handleNextStep()
  }

  useEffect(() => {
    if (indicatorId) {
      buildLink(indicatorId)
    }
  }, [indicatorId])

  return (
    <Grid>
      <Stack>
        <Typography variant="h4">
          Escolha como e para quem enviar o convite:
        </Typography>
      </Stack>

      {sharedUrl && (
        <Stack flexDirection="row" justifyContent="center" mt={4}>
          <WhatsappShareButton
            url={sharedUrl}
            title={titleMemo}
            onClick={handleSendInvite}
          >
            <WhatsappIcon size={80} round />
          </WhatsappShareButton>

          {/*
          <Box ml={3}>
            <FacebookMessengerShareButton
              url={sharedUrl}
              appId="1"
              onClick={handleSendInvite}
              title={titleMemo}
            >
              <FacebookMessengerIcon size={80} round />
            </FacebookMessengerShareButton>
          </Box>

      */}
        </Stack>
      )}
    </Grid>
  )
}
